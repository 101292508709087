import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import Seo from "../components/meta/Seo";
import ServiceSchema from "../components/meta/ServiceSchema";
import FaqSchema from "../components/meta/FaqSchema";
import Layout from "../components/common/Layout";
import HeaderBackground from "../components/blocks/HeaderBackground";
import SliceZone from "../components/common/SliceZone";
import { constructActiveDoc } from "../helpers";

const businessPublic = ({ data }) => {
  if (!data) return null;

  const { settings, page } = data;
  const {
    title,
    description,
    image,
    image_mobile: imageMobile,
    body,
  } = page.data;

  return (
    <>
      <Seo page={page} settings={settings} />
      <ServiceSchema page={page} settings={settings} />
      <FaqSchema body={body} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(page)}>
        <HeaderBackground
          title={title}
          description={description}
          image={image}
          imageMobile={imageMobile}
        />
        <SliceZone slices={body} settings={settings} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query businessPublicQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicChargespotBusinessPublic(lang: { eq: $lang }) {
      ...prismicChargespotBusinessPublicFragment
    }
  }
`;

export default withPrismicPreview(businessPublic);
